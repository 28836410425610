@import "styles/utils";
@import "styles/components";

.signUpContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    h2{
      font-size: 25px;
      line-height: 25px;
      margin-bottom: 53px;
      text-align: left;
      letter-spacing: normal;
      font-weight: 900;
    }
    .offerList {
      width: 100%;
      display: flex;
      flex-direction: column;
        justify-content: center;
        align-items: center;
      margin-bottom: 20px;
      .offerLimitations{
        font-size: 14px;
        line-height: 16px;
        font-weight: 100;
        margin-bottom: 10px;
      }
      .offerRow{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content:space-between;
        margin-bottom: 20px;
      }
    }
  .offerBorder{
    width: 1px;
    height: 100%;
    background-color: #E5E5E5;
    @include phone() {
      min-height:80px;
    }
  }
  .offerItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .labelSave{
      font-size: 11px;
      height:20px;
      line-height: 16px;
      font-weight: normal;
      padding: 2px 10px 2px 10px;
      background-color: var(--app-dot-color);
      position: absolute;
      top: - 30px;
      right:0;
      border-radius: 15px;
    }
    h3 {
      font-size: 20px;
      margin: 0;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      height:64px;
      span:first-child {
        font-size: 35px;
        font-weight: 900;
        font-family: "Arial Black", Arial, serif;
      }
      span:last-child {
        font-size: 20px;
        font-weight: 100;
        margin-left: 10px;
      }
    }
  }
  .offerItem:first-child{
    justify-content: flex-start;
    align-items: flex-start;
  }
  .offerItem:last-child{
    justify-content: flex-start;
    align-items: center;
  }
}
.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  form {
    width: 100%;
  }

  input {
    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
  .loginInput {
    width: 100%;
  }
  .formButton{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      @include phone() {
        height:50px;
        @include typography(2.1rem)
      }
      margin:0;
    }
  }
}
.headerLogo {
  width:164px;
  height:180px;
  display: flex;
    justify-content: center;
    align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
  @include phone-landscape(){
    width: 60px;
    height: 60px;
  }
}